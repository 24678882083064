import React, { useState } from 'react';
import { NavBarStyled, NavbarMobile } from './style';
import logonovomundo from '../../assets/logo_novomundo.png'
import { useMediaQuery } from 'react-responsive';

export function Navbar({ activePage, setPage }) {
    const isMobile = useMediaQuery({ maxWidth: 933 })
    const [menuOpen, setMenuOpen] = useState(false);
    return isMobile ? (
        <NavbarMobile>
            <div className="icons">
                <a target="_blank" href="https://www.instagram.com/novomundo_13/" className="icon1" rel='noreferrer'>
                    <i className="fa-brands fa-instagram" aria-hidden="true"></i>
                </a>
                <a target="_blank" href="https://www.facebook.com/NovomundoTribe" className="icon2" rel='noreferrer'>
                    <i className="fa-brands fa-square-facebook" aria-hidden="true"></i>
                </a>    
                <a target="_blank" href="https://www.youtube.com/@Novomundo_13" rel='noreferrer'>
                    <i className="fa-brands fa-youtube" aria-hidden="true"></i>
                </a>
                
                <i onClick={() => setMenuOpen((old) => !old)} className={`fa-solid fa-${menuOpen ? 'x' : 'bars'}`} aria-hidden="true"></i>
            </div>
            <div className="logo">
                <img src={logonovomundo} alt="" />
            </div>

            {menuOpen && (
                <div className="menu">
                    <ul>
                        {activePage !== 'home' && (
                            <li>
                                <span onClick={() => {
                                    setPage('home');
                                    setMenuOpen(false)
                                }}>HOME</span>
                            </li>
                        )}
                        <li>
                            <span onClick={() => {
                                setPage('bio');
                                setMenuOpen(false)
                            }}>BIO</span>
                        </li>
                        <li>
                            <span onClick={() => {
                                setPage('merch');
                                setMenuOpen(false)
                            }}>MERCH</span>
                        </li>
                        <li>
                            <span onClick={() => {
                                window.open('https://novomundo13.bandcamp.com/merch');
                                setMenuOpen(false)
                            }}>SHOP</span>
                        </li>
                        <li>
                            <span onClick={() => {
                                setPage('contacts');
                                setMenuOpen(false)
                            }}>CONTACTS</span> 
                        </li>
                    </ul>
                </div>
            )}
        </NavbarMobile>
    ) :
    (
        <NavBarStyled activePage={activePage}>
            {activePage === 'home' ? (
               <>
                    <img src={logonovomundo} alt="" />
                    <ul>
                        <li>
                            <span onClick={() => setPage('bio')}>BIO</span>
                        </li>
                        <li>
                            <span onClick={() => setPage('merch')}>MERCH</span>
                        </li>
                        <li>
                            <span onClick={() => setPage('contacts')}>CONTACTS</span> 
                        </li>
                    </ul>
               </> 
            ) : (
                <>
                    <div className="logo">
                        <img src={logonovomundo} alt="" />
                    </div>
                    <ul>
                        <li>
                            <span onClick={() => setPage('home')}>
                                {activePage.toUpperCase()}
                                <i className="fa-solid fa-x" />
                            </span>
                        </li>
                    </ul>
                </>
            )}



            <div className="icons">
                <a target="_blank" href="https://www.instagram.com/novomundo_13/" rel='noreferrer' className="icon1">
                    <i className="fa-brands fa-instagram" aria-hidden="true"></i>
                </a>
                <a target="_blank" href="https://www.facebook.com/NovomundoTribe" rel='noreferrer' className="icon2">
                    <i className="fa-brands fa-square-facebook" aria-hidden="true"></i>
                </a>    
                <a target="_blank" href="https://www.youtube.com/@Novomundo_13" rel='noreferrer'>
                    <i className="fa-brands fa-youtube" aria-hidden="true"></i>
                </a>
            </div>
            <a target="_blank" href="https://novomundo13.bandcamp.com/merch" rel='noreferrer'>
                SHOP
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
            </a>
        </NavBarStyled>
    )
}